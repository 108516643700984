<template>
  <login-form />
</template>

<script>
import LoginForm from '../components/Authentication/LoginForm';

export default {
  components: {
    'login-form': LoginForm,
  },
}
</script>
